 <template>
  <div class="Index_map1" ref="vlbox">
    <div id="map"></div>
    <div class="info" v-show="info == 1" ref="info">
      <div class="info_left">
        <p>
          <img src="../assets/imgs/zzjg.png" alt="" />
          {{ pjForm.name }}
        </p>
        <div class="item">
          <p class="gray999">所属区域</p>
          <p class="gray666">{{ pjForm.areaName }}</p>
        </div>
        <div class="item">
          <p class="gray999">项目进度</p>
          <p class="gray666" >
              <span v-if="pjForm.schedule == 0">尚未计划</span>
              <span v-if="pjForm.schedule == 1">正在实施</span>
              <span v-if="pjForm.schedule == 2">正在运维</span>
              <span v-if="pjForm.schedule == 3">已竣工</span>
          </p>
        </div>
        <div class="item">
          <p class="gray999">项目类型</p>
          <p class="gray666"><span v-if="pjForm.orgType == 1">自营</span>
              <span v-if="pjForm.orgType == 2">渠道</span></p>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="search">
        <div class="search_top">
               <el-select
              v-model="formData.input"
              clearable filterable
              size="mini"
              value-key='id'
              placeholder="请输入项目名称"
            >
              <el-option
                v-for="item in porjectList"
                :key="item.id"               
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          <button @click="itemChange">

            <i class="el-icon-search"></i>
            查询
          </button>
            <i v-show="btnShow" @click="btnShow = !btnShow" class="el-icon-arrow-down"></i>
            <i v-show="!btnShow" @click="btnShow = !btnShow" class="el-icon-arrow-up"></i>
        </div>
        <div v-show="btnShow" class="search_center">
          <div class="btnGroup1">
            <span>项目来源</span>
            <button @click="typeSwitch('')" :class="{ lighter: lineBgc == '' }">
              全部
            </button>
            <button @click="typeSwitch(1)" :class="{ lighter: lineBgc == 1 }">
              自营
            </button>
            <button @click="typeSwitch(2)" :class="{ lighter: lineBgc == 2 }">
              代理
            </button>
          </div>
          <div class="btnGroup1">
            <span>运维状态</span>
            <button @click="scheduleSwitch('')" :class="{ lighter: lineBgc2 === '' }">
              全部
            </button>
            <button @click="scheduleSwitch(2)" :class="{ lighter: lineBgc2 == 2 }">
              正常
            </button>
            <button @click="scheduleSwitch(5)" :class="{ lighter: lineBgc2 == 5 }">
              到期
            </button>
            <button @click="scheduleSwitch(0)" :class="{ lighter: lineBgc2 === 0}">
              未开始
            </button>
            <button @click="scheduleSwitch(3)" :class="{ lighter: lineBgc2 == 3 }">
              已竣工
            </button>
          </div>
        </div>
      </div>
      <div class="head_bottom">
        <span>项目数量：{{formData.orgTotal}}</span>
        <p v-show="lineBgc!==''">
          <span v-show="lineBgc==1">自营项目：{{formData.typeCount}}</span>
          <span v-show="lineBgc==2">代理项目：{{formData.typeCount}}</span>
        </p>
        <p v-show="lineBgc2!==''">
          <span v-show="lineBgc2==2">正常运维：{{formData.scheduleCount}}</span>
          <span v-show="lineBgc2==5">已到期：{{formData.scheduleCount}}</span>
          <span v-show="lineBgc2===0">未开始：{{formData.scheduleCount}}</span>
          <span v-show="lineBgc2==3">已竣工：{{formData.scheduleCount}}</span>
        </p>
        
      </div>
    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>新增记录</span>
          <span
            @click="indexModal=0;littleForm={linkman:'',phone:'',contactWay:'1',createTime:''};"
            class="pointer">
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
          <div class="input_item">
            <p>联系人：</p>
            <el-input v-model="littleForm.linkman" size="mini"></el-input>
          </div>
          <div class="input_item">
            <p>联系电话：</p>
            <el-input v-model="littleForm.phone" size="mini"></el-input>
          </div>
          <div class="input_item">
            <p>联系方式：</p>
            <el-select v-model="littleForm.contactWay" size="mini">
              <el-option label="现场拜访" value="1"></el-option>
              <el-option label="线上回访" value="2"></el-option>
            </el-select>
          </div>
          <div class="input_item">
            <p>联系时间：</p>
            <el-date-picker
              size="mini"
              v-model="littleForm.createTime"
              :picker-options="pickerOptions1"
              placeholder="请选择联系时间,默认今天"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="input_item">
            <p>联系内容：</p>
            <el-input
              type="textarea"
              v-model="littleForm.cause"
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="foot">
          <button class="finish" @click="over">完成</button>
        </div>
      </div>
    </div>
    <div class="modal6" v-if="modal == 6">
      <div class="inner11111">
        <p class="title">
          <span>{{ form.orgInfo.name }}</span>
          <span
            @click="
              modal = 0;
              form = {
                orgInfo: {},
                profitEstimates: {},
                costAccount: {},
                materialsStatistical: {},
                orderAndPlan: {},
                contractAmount: {},
              };
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div style="padding: 0 20px; max-height: 740px; overflow: auto">
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane
              label="基本信息"
              v-if="permissoin.includes('basic')"
              name="first"
            >
              <el-divider content-position="left">基本信息</el-divider>
              <div style="display: flex; justify-content: space-between">
                <div style="width: 400px">
                  <p class="sel">
                    <span class="spa">项目区域</span>
                    <span>{{ form.areaName }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">项目地址</span>
                    <span>{{ form.orgInfo.orgAddress }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">施工单位</span>
                    <span>{{ form.orgInfo.constructionUnit }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">单位联系人</span>
                    <span>{{ form.orgInfo.headName }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">联系电话</span>
                    <span>{{ form.orgInfo.phone }}</span>
                  </p>
                </div>
                <div style="width: 400px">
                  <p class="sel">
                    <span class="spa">业务员</span>
                    <span>{{ form.orgInfo.updater }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">联系电话</span>
                    <span>{{ form.orgInfo.updaterByPhone }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">运维状态</span>
                    <span v-if="form.orgInfo.isOver" class="colorred"
                      >已到期</span
                    >
                    <span v-else class="colorgreen">运维中</span>
                  </p>
                  <p class="sel">
                    <span class="spa">运维到期时间</span>
                    <span>{{ timestampToTime1(form.orgInfo.overTime) }}</span>
                  </p>
                  <p class="sel" style="position: relative">
                    <span class="spa">备注</span>
                    <!-- <span v-show="remarkstate == 0">{{ remarks }}</span> -->
                    <el-tooltip
                      v-show="remarkstate == 0"
                      class="item"
                      effect="light"
                      :content="remarks"
                      placement="top-start"
                    >
                      <span
                        style="
                          display: inline-block;
                          max-width: 250px;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                        >{{ remarks }}</span
                      >
                    </el-tooltip>

                    <el-input
                      style="
                        position: absolute;
                        top: 0px;
                        left: 80px;
                        z-index: 10000;
                      "
                      type="textarea"
                      v-show="remarkstate == 1"
                      v-model="remarks"
                      clearable
                      size="mini"
                    ></el-input>
                    <span class="ii">
                      <i
                        class="el-icon-edit"
                        @click="remarkstateChange(1)"
                        v-show="remarkstate == 0"
                      ></i>
                      <i
                        style="
                          position: absolute;
                          top: 0px;
                          left: 370px;
                          z-index: 100000;
                        "
                        class="el-icon-check"
                        @click="remarkstateChange(0)"
                        v-show="remarkstate == 1"
                      ></i>
                    </span>
                  </p>
                </div>
                <!-- <div class="map">
                  <PtojectMap
                    :addressData="addressData"
                    @childByValue="childByValue"
                  ></PtojectMap>
                </div> -->
              </div>
              <div>
                <el-divider content-position="left">回款进度</el-divider>
                <p class="sel">
                  <span class="spa">合同总额</span>
                  <span class="colorblue">￥{{ form1.repaymentTotal }}</span>
                </p>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :percentage="form1.repaymentRate"
                ></el-progress>
                <div
                  style="
                    margin-top: 14px;
                    display: flex;
                    justify-content: space-between;
                    width: 600px;
                  "
                >
                  <p class="sel">
                    <span class="spa">累计回款</span>
                    <span class="colorgreen">￥{{ form1.repaymentPaid }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">应收账款</span>
                    <span class="colorred">￥{{ form1.repaymentTotal }}</span>
                  </p>
                </div>

                <el-table :data="tableDatahk" style="width: 1200px">
                  <el-table-column type="index" label="排序" width="60">
                  </el-table-column>
                  <el-table-column prop="userName" label="回款人">
                  </el-table-column>
                  <el-table-column prop="amount" label="回款金额">
                  </el-table-column>
                  <el-table-column prop="createTime" label="收款日期">
                  </el-table-column>
                  <el-table-column prop="merchantName" label="收款方式">
                    <template slot-scope="scope">
                      <span v-if="scope.row.payType == 3">对公帐户</span>
                      <span v-if="scope.row.payType == 1">现金 </span>
                      <span v-if="scope.row.payType == 2">电子汇款</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="bankName"
                    label="收款账户"
                    width="160"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="company"
                    label="付款单位"
                    width="200"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.state == 3">驳回</span>
                      <span v-if="scope.row.state == 1">已到账</span>
                      <span v-if="scope.row.state == 2">待审核</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div>
                <el-divider content-position="left">开票进度</el-divider>
                <p class="sel">
                  <span class="spa">合同总额</span>
                  <span class="colorblue">￥{{ form1.total }}</span>
                </p>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :percentage="form1.invoiceRate"
                ></el-progress>
                <div
                  style="
                    margin-top: 14px;
                    display: flex;
                    justify-content: space-between;
                    width: 600px;
                  "
                >
                  <p class="sel">
                    <span class="spa">累计开票</span>
                    <span class="colorgreen">￥{{ form1.invoicePaid }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">剩余票面</span>
                    <span class="colorred">￥{{ form1.invoiceTotal }}</span>
                  </p>
                </div>
                <el-table :data="tableDatakp" style="width: 1200px">
                  <el-table-column type="index" label="排序" width="60">
                  </el-table-column>
                  <el-table-column prop="amount" label="开票金额">
                  </el-table-column>
                  <el-table-column prop="createTime" label="开票日期">
                  </el-table-column>
                  <el-table-column prop="merchantName" label="发票类型">
                    <template slot-scope="scope">
                      <span v-if="scope.row.invoiceType == 1">普通发票</span>
                      <span v-if="scope.row.invoiceType == 2"
                        >增值税专用发票</span
                      >
                      （{{ scope.row.taxRate }}%）
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="company"
                    label="出票单位"
                    width="200"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="merchantName" label="状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.state == 3">驳回</span>
                      <span v-if="scope.row.state == 4">已作废</span>
                      <span v-if="scope.row.state == 1">已开票</span>
                      <span v-if="scope.row.state == 2">待开票</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="计划详情"
              v-if="permissoin.includes('contract')"
              name="second"
            >
              <p class="sel">
                <span class="spa">合同金额</span>
                <span class="colorblue">{{ form.contractAmount.sum }}</span>
                <span class="gray666"
                  >（硬件材料:￥{{ form.contractAmount.yjcl }}, 系统软件:￥{{
                    form.contractAmount.xtrj
                  }},其他材料:￥{{ form.contractAmount.qtfw }}, ）</span
                >
              </p>
              <p class="sel">
                <span class="spa">累计回款</span>
                <span class="colorgreen">{{ form.contractAmount.ljhk }}</span>
              </p>
              <p class="sel">
                <span class="spa">应收账款</span>
                <span class="colorred">{{ form.contractAmount.yszk }}</span>
              </p>
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  v-for="(item, index) in contractableData"
                  :key="index"
                  :name="index"
                >
                  <template slot="title">
                    <p>
                      <span
                        >{{ item.createTime }} ·
                        {{ item.orderType == 1 ? "初装计划" : "补充计划" }} ·
                        ￥{{ item.allPrice }}</span
                      >
                    </p>
                  </template>
                  <p>
                    硬件材料总计：<span class="colorblue"
                      >￥{{ item.yjcb }}</span
                    >，系统软件总计：<span class="colorblue"
                      >￥{{ item.xtrj }}</span
                    >
                    ，其他服务：<span class="colorblue">￥{{ item.qtfw }}</span>
                  </p>
                  <el-table :data="item.item" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="产品名"
                      width="200"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="operNumber" label="数量">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价（元）">
                    </el-table-column>
                    <el-table-column prop="taxUnitPrice" label="含税单价（元）">
                    </el-table-column>
                    <el-table-column prop="taxMoney" label="税金（元）">
                    </el-table-column>
                    <el-table-column prop="taxLastMoney" label="小计（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="材料统计"
              v-if="permissoin.includes('material')"
              name="third"
            >
              <p class="sel">
                <span class="spa">计划用量</span>
                <span class="colorblue">{{
                  form.materialsStatistical.jhyl
                }}</span>
              </p>
              <p class="sel">
                <span class="spa">实际用量</span>
                <span class="colorgreen">{{
                  form.materialsStatistical.sjyl
                }}</span>
              </p>
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  v-for="(item, index) in contractableMateria"
                  :key="index"
                  :name="index"
                >
                  <template slot="title">
                    <p>
                      <span
                        >{{ item.createTime }} ·
                        <span v-if="item.orderType == 1">初装计划</span>
                        <span v-if="item.orderType == 2">补充计划</span>
                        <span v-if="item.orderType == 1">其他计划</span>
                        · ￥{{ item.allPrice }}</span
                      >
                    </p>
                  </template>
                  <p>
                    <span style="margin-right: 20px">
                      <span class="gray999">计划使用:</span>
                      {{ item.planNumber }}</span
                    >
                    <span style="margin-right: 20px">
                      <span class="gray999">实际使用:</span>
                      <span class="colorgreen">{{ item.actualNumber }}</span>
                    </span>
                    <!-- <span
                      class="colorblue"
                      style="cursor: pointer; margin-right: 20px"
                      >查看出库清单</span
                    >
                    <span class="colorblue" style="cursor: pointer"
                      >查看实际使用清单</span
                    > -->
                  </p>
                  <el-table :data="item.stockList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="产品名"
                      :show-overflow-tooltip="true"
                      width="180"
                    >
                    </el-table-column>
                    <el-table-column prop="planNumber" label="计划数量">
                    </el-table-column>
                    <el-table-column prop="applyNumber" label="申领数量">
                    </el-table-column>
                    <el-table-column prop="actualNumber" label="实际用量">
                    </el-table-column>
                    <el-table-column
                      prop="requisitionNumber"
                      label="实际出库数量"
                    >
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="成本核算"
              v-if="permissoin.includes('cost')"
              name="fourth"
            >
              <p class="sel">
                <span class="spa">总成本</span>
                <span class="colorblue">￥{{ costForm.allCost }}</span>
              </p>
              <p class="sel">
                <span class="spa">利润估算</span>
                <span class="colorblue">￥{{ costForm.grossProfit }}</span>
              </p>
              <p class="sel">
                <span class="spa">毛利率</span>
                <span class="colorblue">{{ costForm.rate }}</span>
              </p>
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  :title="'硬件成本￥' + form.costAccount.yjcb"
                  name="1"
                >
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorblue">{{ form.costAccount.yjcb }}</span>
                  </p> -->
                  <el-table :data="costForm.hardwareList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="产品名"
                      width="140"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="num" label="数量"> </el-table-column>
                    <el-table-column prop="handsPersonName" label="实施人">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价（元）">
                    </el-table-column>
                    <el-table-column prop="subtotal" label="小计（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item  name="2" :title="'软件成本￥' + costForm.softwareCost">
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorgreen"
                      >{{ costForm.softwareCost }}</span
                    >
                  </p> -->
                  <el-table :data="costForm.softwareList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="软件系统"
                      width="140"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="num" label="数量">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价（元）">
                    </el-table-column>
                    <el-table-column prop="allPrice" label="小计（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item
                  :title="'运维成本￥' + form.costAccount.yycb"
                  name="3"
                >
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorred">{{ form.costAccount.yycb }}</span>
                  </p> -->
                  <el-table
                    :data="costForm.operationList"
                    style="width: 1200px"
                  >
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <!-- <el-table-column prop="deviceNum" label="类型">
                      维修
                    </el-table-column> -->
                    <el-table-column prop="completionTime" width="190" :show-overflow-tooltip="true" label="实施日期">
                    </el-table-column>
                    <el-table-column prop="handsPersonName" label="实施人">
                    </el-table-column>
                    <el-table-column
                      prop="实用硬件"
                      label="实用硬件"
                      width="220"
                      :show-overflow-tooltip="true">
                      <!-- 平台人脸1台（总价：￥1300） -->
                    </el-table-column>

                    <el-table-column prop="差旅费" label="差旅费">
                    </el-table-column>
                    <el-table-column prop="交通费" label="交通费">
                    </el-table-column>
                    <el-table-column prop="其他费用" label="其他费用">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item
                  :title="'商务成本￥' + form.costAccount.swcb"
                  name="4"
                >
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorred">{{ form.costAccount.swcb }}</span>
                  </p> -->
                  <el-table :data="costForm.businessList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="商务名目"
                      width="140"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="creatorName" label="创建人">
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建日期">
                    </el-table-column>
                    <el-table-column prop="taxLastMoney" label="总价（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="安装计划"
              v-if="permissoin.includes('install')"
              name="seventh"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDataorder.length }}</span>
              </p>
              <el-table :data="tableDataorder" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>名称</span>
                          <span>数量</span>
                          <span>税率（%）</span>
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.硬件材料 != null"
                        >
                          硬件材料
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.硬件材料"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.软件系统 != null"
                        >
                          软件系统
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.软件系统"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.其他服务 != null"
                        >
                          其他服务
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.其他服务"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                      <div class="table_item">
                        <p class="table_item_title">现场照片</p>
                        <el-image
                          v-for="item in props.row.fileArr"
                          :key="item"
                          style="width: 80px; height: 100px; margin-right: 20px"
                          :src="item"
                          :preview-src-list="props.row.fileArr"
                        >
                        </el-image>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>
                <el-table-column label="计划类型">
                  <template slot-scope="scope">
                    <span v-if="scope.row.orderType == 1">初始计划</span>
                    <span v-if="scope.row.orderType == 2">补充计划</span>
                  </template>
                </el-table-column>
                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="handsPersonName" label="实施人">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 3" class="colorblue"
                      >待实施</span
                    >
                    <span v-if="scope.row.status == 4" class="colorblue"
                      >待分配</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                    <span v-if="scope.row.status == 5" class="colorblue"
                      >待领取</span
                    >
                    <span v-if="scope.row.status == 9" class="colorred"
                      >安装退回</span
                    >
                    <span v-if="scope.row.status == 8" class="colorred"
                      >安装失败</span
                    >
                    <span v-if="scope.row.status == 7" class="colorred"
                      >安装待确认</span
                    >
                    <span v-if="scope.row.status == 6" class="colorblue"
                      >待出库</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  width="170"
                  :show-overflow-tooltip="true"
                  prop="materialRemark"
                  label="安装结果"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="商务计划"
              v-if="permissoin.includes('business')"
              name="eighth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDatabusiness.length }}</span>
              </p>
              <el-table :data="tableDatabusiness" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>商务名目</span>
                          <span>数量</span>
                          <!-- <span>税率（%）</span> -->
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <div
                          class="jstitle"
                          v-for="item in props.row.itemList"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <!-- <span>{{item.taxRate*100}}</span> -->
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>
                <el-table-column
                  width="150"
                  :show-overflow-tooltip="true"
                  prop="contractorsName"
                  label="参建单位"
                >
                </el-table-column>
                <el-table-column prop="linkman" label="联系人">
                </el-table-column>
                <el-table-column prop="phone" label="联系电话">
                </el-table-column>
                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="运营计划"
              v-if="permissoin.includes('operate')"
              name="tenth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDataoperation.length }}</span>
              </p>
              <el-table :data="tableDataoperation" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>名称</span>
                          <span>数量</span>
                          <span>税率（%）</span>
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.itemList != null"
                        >
                          其他服务
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.itemList"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                      <div class="table_item">
                        <p class="table_item_title">现场照片</p>
                        <el-image
                          v-for="item in props.row.fileArr"
                          :key="item"
                          style="width: 80px; height: 100px; margin-right: 20px"
                          :src="item"
                          :preview-src-list="props.row.fileArr"
                        >
                        </el-image>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>

                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="handsPersonName" label="实施人">
                </el-table-column>
                <el-table-column
                  prop="description"
                  label="问题描述"
                  width="150"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="materialName" label="维护对象">
                </el-table-column>
                <el-table-column label="支付方式">
                  <template slot-scope="scope">
                    <span v-if="scope.row.payWay == 1">现金支付</span>
                    <span v-if="scope.row.payWay == 2">线上支付</span>
                  </template>
                </el-table-column>
                <el-table-column prop="receivable" label="现场实收">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 3" class="colorgreen"
                      >待指派</span
                    >
                    <span v-if="scope.row.status == 4" class="colorblue"
                      >待实施</span
                    >
                    <span v-if="scope.row.status == 5" class="colorred"
                      >已退单</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cause"
                  width="140"
                  :show-overflow-tooltip="true"
                  label="处理结果"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="维修计划"
              v-if="permissoin.includes('repair')"
              name="elevenwwth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDatamaintain.length }}</span>
              </p>
              <el-table :data="tableDatamaintain" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>名称</span>
                          <span>数量</span>
                          <span>税率（%）</span>
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.硬件材料 != null"
                        >
                          硬件材料
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.硬件材料"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.其他服务 != null"
                        >
                          其他服务
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.其他服务"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                      <div class="table_item">
                        <p class="table_item_title">现场照片</p>
                        <el-image
                          v-for="item in props.row.fileArr"
                          :key="item"
                          style="width: 80px; height: 100px; margin-right: 20px"
                          :src="item"
                          :preview-src-list="props.row.fileArr"
                        >
                        </el-image>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>

                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="handsPersonName" label="实施人">
                </el-table-column>
                <el-table-column
                  prop="description"
                  label="维修原因"
                  width="150"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="materialName" label="维修对象">
                </el-table-column>
                <el-table-column prop="deviceNum" label="支付方式">
                  <template slot-scope="scope">
                    <span v-if="scope.row.payWay == 1">现金支付</span>
                    <span v-if="scope.row.payWay == 2">线上支付</span>
                  </template>
                </el-table-column>
                <el-table-column prop="receivable" label="现场实收">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 3" class="colorblue"
                      >待实施</span
                    >
                    <span v-if="scope.row.status == 4" class="colorblue"
                      >待分配</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                    <span v-if="scope.row.status == 5" class="colorblue"
                      >待领取</span
                    >
                    <span v-if="scope.row.status == 9" class="colorblue"
                      >安装退回</span
                    >
                    <span v-if="scope.row.status == 6" class="colorblue"
                      >待出库</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="cause"
                  label="处理结果"
                  width="140"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="安心计划"
              v-if="permissoin.includes('peace')"
              name="tenqqqth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDatarenew.length }}</span>
              </p>
              <el-table :data="tableDatarenew" style="width: 1200px">
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>
                <el-table-column
                  prop="deviceNum"
                  label="计划名目"
                  width="140"
                  :show-overflow-tooltip="true"
                >
                  运维服务一年
                </el-table-column>
                <el-table-column prop="createTime" label="创建日期">
                  <template slot-scope="scope">{{
                    timestampToTime1(scope.row.createTime)
                  }}</template>
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="num" label="数量">
                  <template slot-scope="scope">
                    {{
                      scope.row.itemList.length == 1
                        ? scope.row.itemList[0].operNumber
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="p" label="单价">
                  <template slot-scope="scope">
                    {{
                      scope.row.itemList.length == 1
                        ? scope.row.itemList[0].taxUnitPrice
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="al" label="含税总价（元）">
                  <template slot-scope="scope">
                    {{
                      scope.row.itemList.length == 1
                        ? scope.row.itemList[0].taxLastMoney
                        : ""
                    }}
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="往来记录"
              name="tenqqqthqq"
              v-if="permissoin.includes('Intercourse')"
            >
              <p class="sel">
                <span class="spa">联系记录</span>
                <span class="colorred" style="margin-right: 20px">{{
                  activities.length
                }}</span>
                <button
                  class="btn2"
                  v-if="permissoin.includes('IntercourseAdd')"
                  style="height: 26px; line-height: 26px"
                  @click="openLittleModal"
                >
                  <i class="el-icon-plus"></i>
                  新增
                </button>
              </p>
              <el-timeline>
                <el-timeline-item
                  v-for="(item, index) in activities"
                  :key="index">
                  <p>联系人：{{ item.linkman }} · {{ item.phone }}</p>
                  <p>
                    联系方式：{{
                      item.contactWay == 1 ? "现场拜访" : "线上回访"
                    }}
                  </p>
                  <p>联系内容：{{ item.cause }}</p>
                  <p class="gray999" style="margin-top: 12px">
                    {{ item.createTime }} · {{ item.userName }}
                  </p>
                </el-timeline-item>
              </el-timeline>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import AMap from "AMap";
import { timestampToTime1,getNowTime1 } from "@/utils/getTime.js";
import {
homeView,
 projectStatistics,
  projectStatisticsMateria,
  sysOrgUpdate,
  getProgressInfo,
  cost,recordAdd,
  recordList,orderanddistributor,
  orderbusiness,
  ordermaintain,
  orderoperation,
  orderrenew,
  wxGetOrgInfo,
} from "@/api/apis.js";
// import PtojectMap from "@/components/PtojectMap.vue";
export default {
  data() {
    return {
       pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //当天之前的时间可选
        },
      },
      map: null,
      btnShow:true,
      remarkstate:0,
      lineBgc:'',
      remarks:'',
      addressData:{},
      permissoin:[],
      lineBgc2:'',
      formData:{},
      options: [],
      activities:[],
      getNowTime1,
       form: {
        orgInfo: {},
        profitEstimates: {},
        costAccount: {},
        materialsStatistical: {},
        orderAndPlan: {},
        contractAmount: {},
      },
      indexForm:{},
      modal:0,
       tableDatahk: [],
      tableDatakp: [],
      tableDataorder: [],
      tableDatabusiness: [],
      tableDataoperation: [],
      tableDatarenew: [],
      tableDatamaintain: [],
       activeName: "first",
      activeNames: ["1"],
      indexModal: 0,
      littleForm: { linkman: "", phone: "", contactWay: "1",createTime:'' },
      markerIcon: [],
      sign2: require("@/assets/imgs/loc_green.png"),
      sign5: require("@/assets/imgs/loc_red.png"),
      sign0: require("@/assets/imgs/loc_yellow.png"),
      sign3: require("@/assets/imgs/loc_gray.png"),
      signed: require("@/assets/imgs/project-elsected.png"),
      zoom: 9,
      center: [104.07, 30.67],
      mapInfoWin: null,
      //当前播放视屏监控点号
      currentCIColde: [],
       cameraindexcode: "",
      //当前播放视屏序列
      currentSequence: 0,
      //定时器
      timeOut: 0,
      popup:0,
      //插件对象
      oWebControl: null,
      treeList:[],
      timestampToTime1,
      treeData: [],
      porjectList:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      hide:1,
      pjForm:{},
      info: 0,
      projectP:-1,
      tableData: [],
    };
  },

  methods: {
    RenderingPointer() {
      var _this =this
      this.markerIcon = [];
      this.porjectList.forEach((item) => {
        let arr=item.coordinates?item.coordinates.split(','):[104.07, 30.67]
      let markerIcon = new AMap.Marker({    
        position: new AMap.LngLat(arr[0],arr[1]),
        icon: _this.dataSwitch(item.schedule),
      });
      this.markerIcon.push(markerIcon);
      markerIcon.on("mouseover", function () {
        _this.info=1     
        _this.pjForm=item  
      });
      markerIcon.on("mousemove", function (e) {
        _this.$refs.info.style.left=e.pixel.x+16+'px'
        _this.$refs.info.style.top=e.pixel.y+16+'px'     
      });
       markerIcon.on("mouseout", function () {
        _this.info=0  
        _this.pjForm={}
      });
      markerIcon.on("click", function () {
        console.log(111);
        
        _this.handleCommand(item)
      });
      // markerIcon.on("dblclick", function () {
      //   _this.getDivice(item.id,2)       
      // });
      });
      this.map.add(this.markerIcon);
    },
    itemChange(){
       this.pjForm=this.formData.input  
      this.info=1   
       this.$refs.info.style.left='51.5%'
        this.$refs.info.style.top='50.5%'   
       this.map.setZoomAndCenter(14, [
          this.formData.input.coordinates.split(',')[0],
          this.formData.input.coordinates.split(',')[1]
        ]);    
    },
    typeSwitch(i){
      this.lineBgc=i
      this.map.remove(this.markerIcon);
      this.getData()
    },
    scheduleSwitch(i){
      this.lineBgc2=i
      this.map.remove(this.markerIcon);
      this.getData()
    },
    dataSwitch(i){
      switch (i) {
        case 2:
          return this.sign2 ;
          case 1:
          return this.sign2 ;
        case 5:
          return this.sign5 ;
          case 0:
          return this.sign0 ;
          case 3:
          return this.sign3 ;
      }
    },
    getData() {
      let data={orgType:this.lineBgc,schedule:this.lineBgc2}
      homeView(data).then((res) => {
        if (res.code == 200) {
          this.porjectList=res.data.list||[]
          this.formData=res.data
          this.RenderingPointer()
        }
      });
    },
     openLittleModal() {
      this.littleForm.linkman = this.form.orgInfo.headName;
      this.littleForm.phone = this.form.orgInfo.phone;
      this.littleForm.contactWay = "1";
      this.littleForm.createTime=getNowTime1()
      this.indexModal = 1;
    },
    over() {
      let data = {
        linkman: this.littleForm.linkman,
        phone: this.littleForm.phone,
        contactWay: this.littleForm.contactWay,
        cause: this.littleForm.cause,
        createTime: this.littleForm.createTime || getNowTime1(),
        projectId: this.indexForm.id,
      };
      recordAdd(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.indexModal = 0;
          this.littleForm = {linkman:'',phone:'',contactWay:'1',createTime:''};
          this.getRecordList();
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    getRecordList() {
      recordList({ projectId: this.indexForm.id }).then((res) => {
        if (res.code == 200) {
          this.activities = res.data;
        } else {
          this.activities = [];
        }
      });
    },
     remarkstateChange(i) {
      if (i == 1) {
        this.remarkstate = 1;
      } else if (i == 0) {
        sysOrgUpdate({ id: this.indexForm.id, remarks: this.remarks }).then(
          (res) => {
            if (res.code == 200) {
              this.remarkstate = 0;
              this.$message({
                type: "success",
                message: "修改成功",
              });
            } else {
              this.$message.error(res.data.msg);
            }
          }
        );
      }
    },
     childByValue(v) {
      this.ruleForm.latitude = v.lat;
      this.ruleForm.longitude = v.lng;
      this.ruleForm.orgAddress = v.address;
      this.ruleForm.areaName = v.areaName;
      this.ruleForm.coordinates =
        this.ruleForm.longitude + "," + this.ruleForm.latitude;
      this.$forceUpdate();
    },
    handleCommand(d) {
      this.indexForm.id = d.id;
      let data = { id: d.id };
      let data2 = { projectId: d.id };
      wxGetOrgInfo(data).then((res) => {
        if (res.code == 200) {
          this.modalState = 1;
          this.form = res.data;
          this.remarks = res.data.orgInfo.remarks;
          this.addressData = {
            coordinates: res.data.orgInfo.coordinates,
            type: 1,
          };
          this.modal = 6;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      getProgressInfo({ id: d.id, type: "1" }).then((res) => {
        if (res.code == 200) {
          this.form1 = res.data;
          this.tableDatahk = res.data.repayment || [];
          this.tableDatakp = res.data.invoice || [];
        } else {
          this.$message.error(res.data.msg);
        }
      });
      orderanddistributor({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          // this.tableDataorder= res.data.list
          this.tableDataorder = res.data.map((item) => {
            item.fileArr = (item.file || "").split(",");
            return item;
          });
        } else {
          this.tableDataorder = [];
        }
      });
      orderbusiness({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          this.tableDatabusiness = res.data;
        } else {
          this.tableDatabusiness = [];
        }
      });
      ordermaintain(data2).then((res) => {
        if (res.code == 200) {
          this.tableDatamaintain = res.data.map((item) => {
            item.fileArr = (item.file || "").split(",");
            return item;
          });
        } else {
          this.tableData = [];
        }
      });
      orderoperation({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          this.tableDataoperation = res.data.map((item) => {
            item.fileArr = (item.file || "").split(",");
            return item;
          });
        } else {
          this.tableDataoperation = [];
        }
      });
      orderrenew({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          this.tableDatarenew = res.data;
          // this.tableDatarenew = res.data.list.map(item=>{
          //     item.p=item.itemList?item.itemList[0]taxUnitPrice:''
          //     return item
          // });
        } else {
          this.tableDatarenew = [];
        }
      });
      this.getRecordList();
      projectStatistics(d.id).then((res) => {
        if (res.code == 200) {
          this.contractableData = res.data.list;
        } else {
          this.contractableData = [];
          this.$message.error(res.data.msg);
        }
      });
      cost(d.id).then((res) => {
        if (res.code == 200) {
          this.costForm = res.data;
        } else {
          this.costForm = {};
          this.$message.error(res.data.msg);
        }
      });
      projectStatisticsMateria(d.id).then((res) => {
        if (res.code == 200) {
          this.contractableMateria = res.data;
        } else {
          this.contractableMateria = [];
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created(){
  },
  mounted() {
    this.permissoin = [];
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = '/index/Project';
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    var _this = this;
    //实例地图对象
    var map = new AMap.Map("map", {
      zoom: this.zoom, //级别
      center: this.center, //中心点坐标
      // mapStyle: "amap://styles/d3433910ef48669918e31f34b4d15a73",
      mapStyle: "amap://styles/4279f2f4de2b4ad3291fd6287eb9878d",
      // lang:'en'
    });
    this.map = map;
    map.on("click", function () {
      _this.info = 0;
    });
    this.getData()
  },
  //   components: {
  //   PtojectMap,
  // },
};
</script>

<style lang="less" >
.Index_map1 {
  width: 100%;
  height: 100%;
  position: relative;
  #map {
    width: 100%;
    height: 100%;
  }

  .info {
    position: absolute;
    width: 220px;
    height: 180px;
    background-color: #fff;
    display: flex;
    font-size: 12px;
    .info_left {
      width: 220px;
      // height: 180px;
      // box-sizing: border-box;
      background-color: #f5f5f5;
      border-radius: 8px;
      padding-top: 30px;
      padding-left: 18px;
      & > p:nth-child(1) {
        font-size: 14px;
        margin-bottom: 20px;
        img {
          margin-right: 6px;
         
        }
      }
      .item {
        display: flex;
        line-height: 30px;
        & > p:nth-child(1) {
          width: 60px;
        }
        & > p:nth-child(2) {
          width: 140px;
        }
      }
    }
    .info_right {
      width: 360px;
      height: 380px;
      box-sizing: border-box;
      border-radius: 0 8px 8px 0;
      padding-left: 20px;
      .right_title {
        line-height: 44px;
        font-size: 14px;
        img {
          margin-left: 6px;
          position: relative;
          top: -4px;
        }
      }
      .el-table {
        th {
          background-color: #fff;
          padding: 0px;
        }
        .cell {
          line-height: 30px;
        }
        i{
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
  .modal6 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    .inner11111 {
      width: 1250px;
      height: 790px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        // margin-bottom: 20px;
        // border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .el-table th {
        background-color: #fff;
        padding: 0px;
      }
      .table_item {
        margin-right: 60px;
        margin-left: 60px;
        font-size: 12px;
        .table_item_title {
          font-size: 14px;
          color: #000;
          line-height: 30px;
        }
        .jstitle {
          line-height: 30px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          & > span:nth-child(1) {
            width: 150px;
          }
          & > span:nth-child(2) {
            width: 60px;
          }
          & > span:nth-child(3) {
            width: 60px;
          }
          & > span:nth-child(4) {
            width: 60px;
          }
        }
      }
      .el-progress {
        width: 600px;
      }
      .clitem {
        .sel1 {
          margin-right: 20px;

          .spa {
            display: inline-block;
            color: #999;
            width: auto;
          }
        }
      }
      .map {
        width: 300px;
        height: 200px;
      }
      .ii {
        i {
          cursor: pointer;
          font-size: 14px;
          line-height: 26px;
          margin-left: 20px;
        }
      }
      .el-tabs--border-card {
        min-height: 730px;
      }
      .sel {
        display: flex;
        margin-bottom: 14px;
        line-height: 26px;
        .el-input__inner {
          width: 200px;
        }
        .el-input {
          width: 200px;
        }
        .el-select .el-input {
          width: 200px;
        }
      }
      .spa {
        display: inline-block;
        width: 80px;
        color: #999;
      }
    }
  }
    .head {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    .search {
      background-color: rgba(45, 57, 77, 0.7);
      border-radius: 6px;
      .search_top {
        display: flex;
        padding: 10px 20px 10px 10px;
        button {
          width: 60px;
          height: 28px;
          background-color: #0090ff;
          color: #fff;
          border-radius: 4px;
          margin-left: 10px;
          margin-right: 20px;
          cursor: pointer;
        }
        &>i{
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          position: relative;
          top: 6px;
        }
      }
      .el-select {
          .el-input {
            width: 250px;
          }
          }
       .search_center {
        border-top: 1px solid #424a55;
        padding: 10px 0 10px 20px;
        font-size: 12px;
        .btnGroup1 {
          margin-bottom: 10px;
          span {
            color: #999;
            margin-right: 16px;
          }
          button {
            width: 48px;
            height: 22px;
            // background-color: #15bb9b;
            color: rgb(211, 210, 210);
            border-radius: 6px;
            cursor: pointer;
            box-sizing: border-box;
            text-align: center;
            line-height: 22px;
            border: 1px solid #0090ff;
            background-color: rgba(45, 57, 77, 0.7);
            margin-right: 10px;
            font-size: 12px;
          }
        }
        .lighter {
          background-color: #0090ff !important;
          color: #ffffff !important;
          // filter: brightness(1.2);
        }
      }
    }
    .head_bottom {
      font-size: 12px;
      line-height: 40px;
      height: 40px;
      box-sizing: border-box;
      padding: 0 30px;
      border-radius: 6px;
      background-color: rgba(45, 57, 77, 0.7);
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      span {
        color: #fff;
      }
    }
    }
}
</style>